<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Expense</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Month Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Expense Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select class="mg-t-5 mr-2" v-model="params.session_year" >
            <option v-for="(year,index) in sessionYears" :key="index" :value="year">{{year}}/{{parseInt(year.substring(year.length-2)) + 1}}</option>
          </select>
          <select v-model="params.branch" class="filter-input mg-t-5 mr-2" @change="searchFilter">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in expenseDataAttributes.branchs"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select v-model="params.account" class="filter-input mg-t-5 mr-2" @change="searchFilter">
            <option value="">All Account</option>
            <option
              v-for="(account, index) in expenseDataAttributes.accounts"
              :key="index"
              :value="account.id"
            >
              {{ account.account_name }}
            </option>
          </select>
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/expense">Account Wise</option>
              <option value="/general-report/expense/branch-wise">Branch Wise</option>
              <option value="/general-report/expense/day-wise">Day Wise</option>
              <option value="/general-report/expense/month-wise">Month Wise</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
       <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Expense: {{parseDigitForSlip(expenseDataAttributes.total_expenses)}}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Direct Expense: {{parseDigitForSlip(expenseDataAttributes.total_direct_expenses)}}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Indirect Expense: {{parseDigitForSlip(expenseDataAttributes.total_indirect_expenses)}}</span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-75p">Month</th>
              <th class="wd-20p">Amount</th>
            </tr>
          </thead>
          <tbody v-if="!loading && monthWiseDatas.length > 0">
          <tr v-for="(expense,index) in monthWiseDatas" :key="index">
            <th scope="row" class="table-start-item">{{ ++index }}</th>
              <td>{{expense.month}} - {{expense.year}}</td>
              <td>{{parseDigitForSlip(expense.total_amount)}}</td>
              
            </tr>
          </tbody>
         <tbody v-else-if="error">
            <tr>
              <td colspan="11" class="text-center">{{error_message}}</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
             <tr>
              <td colspan="11" class="text-center">Loading...</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && monthWiseDatas.length == 0">
            <tr>
              <td colspan="11" class="text-center">No Records Found.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists4",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("expenseReports",["monthWiseDatas","expenseDataAttributes"])
  },
  data(){
    return{
        sessionYears:['2021'],
        route:this.$route.path,
        params:{
          pageIndex:15,
          session_year:'2021',
          branch:'',
          type:'',
          searched:'',
          from:'',
          day: new Date().getDate(),
          month: new Date().getMonth(),
          year: new Date().getFullYear(),
          to:'',
          offset:0,
          account:'',
        },
        pageSerialNo:1,
        totalPageCount:'',
        page:1,
        loading:false,
        error:false,
        error_message:'', 
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    print(){
      window.print();
    },
    filterReportType() {
      if(this.$route.path!=this.route) {
        this.$router.push(this.route);
      }
    },
    getData(){
      this.getFilteredDataWithoutDates();
    },
    // without month component filter
    getFilteredDataWithoutDates(){
     this.loading = true;
      let params ={
          'pageIndex': this.params.pageIndex,
          'account':this.params.account,
          'branch' : this.params.branch,
          'type':this.params.type,
          'today':'',
          'month':'',
          'year':this.params.year,
          'from' : this.params.from,
          'to' : this.params.to,
          'searched' : this.params.searched,
          'session_years':this.params.session_year,
          'offset':this.params.offset,
        }
    this.ajaxRequest(params);
  },
  ajaxRequest(params){
      this.loading = true;
      Services.getMonthWiseReport(params).then(res=>{
        this.$store.commit("expenseReports/setMonthWiseDatas",res.data.data.data);
        this.$store.commit("expenseReports/setExpenseDataAttributes",res.data.data.attributes);
      }).catch(err=>{
        this.error=false;
        let error = err.response.data.error_message;
        console.log(error);
        this.setNotification(error);
      }).finally(()=>{
        this.loading= false;
      });
    },
    searchFilter(){
      this.page = 1;
      this.pageSerialNo= 1;
      this.params.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    searchKeyword:_.debounce(function(){
      this.page = 1;
      this.pageSerialNo= 1;
      this.params.offset = 0;
      this.getFilteredDataWithoutDates();
    },1000),
    download(){
      Services.getMonthWiseExcel(this.params).then((response)=>{
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const elink = document.createElement("a");
            elink.download = 'expense-month-wise-report.xlsx';
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL (elink.href); // release the URL object
            document.body.removeChild(elink);
      }).catch(err=>{
        console.log(err);
      });
    },
  },
};
</script>